const imageSlide = [
  {
    url: "IMG_0004.JPG",
  },
  {
    url: "IMG_0024.JPG",
  },
];

export default imageSlide;
